import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerRateInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Rate Information</Text>
            </View>

            <View style={baseStyles.tablePadding}>

                <View style={baseStyles.flexBetween}>
                    <Text style={baseStyles.flex}>
                        Buy Rate
                    </Text>
                    <Text>{helper.quoteRate()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text style={baseStyles.flex}>
                        Reserve Profit
                    </Text>
                    <Text>{helper.dealerReserveAmount()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text style={baseStyles.flex}>
                        Sell Rate
                    </Text>
                    <Text>{helper.quoteRate()}</Text>
                </View>

            </View>
        </View>
    )
}