import React, {useCallback, useState} from 'react'
import classnames from "classnames";
import QuoteHelper from "../../utils/QuoteHelper";

import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import {useDealContext} from "../../contexts/DealContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import TableHead from "@mui/material/TableHead";
import {DealPageFeeList} from "./DealPageFeeList";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import {NumericFormat} from "react-number-format";


export const DealPageFees = () => {
    const { setDealStateWithUpdate, updateDealResponse, dealState } = useDealContext()
    const [helper, setHelper] = useState(new QuoteHelper(dealState))
    const [open, setOpen] = useState(false);

    useDidMountEffect(function() {
        setHelper(new QuoteHelper(dealState))
    }, [dealState])

    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })

    const setAllFees = useCallback((financedOrUpfront) => {
        let newDealState = structuredClone(dealState)
        let allFees = newDealState.selected_quote['Quote']['Fees']
        const newFees = []

        allFees.forEach((el, index, array) => {
            const newEl = structuredClone(el)
            newEl['Type'] = financedOrUpfront
            newFees.push(newEl)
        })

        newDealState.selected_quote['Quote']['Fees'] = newFees
        setDealStateWithUpdate(newDealState, true)
    }, [dealState, setDealStateWithUpdate, updateDealResponse])

    if (!(dealState && dealState?.selected_quote)) return null;

    const isLoading = updateDealResponse?.loading

    return (
        <Box className={classnames("dealPageFees", {
            mobile: isTabletOrMobile
        })}>
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                className={'header header-row'}
            >
                <div
                    className={'header-name'}
                >
                    Fees
                </div>
                <Stack
                    direction={'row'}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <div className={'header-amount'}>
                        <NumericFormat
                            value={helper.totalFeeAmount()}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </div>
                    <div>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </div>
                </Stack>
            </Stack>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    className={'header-cell'}
                                    align="left"
                                    width={'60%'}
                                >
                                    Name
                                </TableCell>
                                {
                                    !isTabletOrMobile &&
                                    <React.Fragment>
                                        <TableCell
                                            className={'header-cell'}
                                            align="right"
                                            width={'20%'}
                                        >
                                            Capped
                                            <Switch
                                                label="Label"
                                                disabled={isLoading}
                                                checked={helper.isAllFinanced()}
                                                onChange={() => {
                                                    const helper = new QuoteHelper(dealState)
                                                    const financedOrUpfront = helper.isAllFinanced() ? 'Upfront' : 'Financed'
                                                    setAllFees(financedOrUpfront)
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            className={'header-cell'}
                                            align="right"
                                            width={'20%'}
                                        >
                                            Amount
                                        </TableCell>
                                    </React.Fragment>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <DealPageFeeList
                            fees={helper.dealerFees()}
                            name={'Dealer'}
                            isLoading={isLoading}
                            dealState={dealState}
                            setDealStateWithUpdate={setDealStateWithUpdate}
                            setAllFees={setAllFees}
                        />
                        <DealPageFeeList
                            fees={helper.lenderFees()}
                            name={'Manufacturer'}
                            isLoading={isLoading}
                            dealState={dealState}
                            setDealStateWithUpdate={setDealStateWithUpdate}
                            setAllFees={setAllFees}
                        />
                        <DealPageFeeList
                            fees={helper.registrationFees()}
                            name={'Registration'}
                            isLoading={isLoading}
                            dealState={dealState}
                            setDealStateWithUpdate={setDealStateWithUpdate}
                            setAllFees={setAllFees}
                        />
                        <DealPageFeeList
                            fees={helper.otherFees()}
                            name={'Other'}
                            isLoading={isLoading}
                            dealState={dealState}
                            setDealStateWithUpdate={setDealStateWithUpdate}
                            setAllFees={setAllFees}
                        />
                        </TableBody>
                    </Table>
            </Collapse>
        </Box>
    )
}