import React, {useEffect, useState} from 'react'

import classnames from "classnames";

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import Box from "@mui/material/Box";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {buildTrimAndStyleDescriptionName} from "../../utils/utils";
import {Link} from "react-router-dom";
import {CustomerDisclosurePDF} from "./DisclosurePDFs/Customer/Lease/CustomerDisclosurePDF";
import {DealerDisclosurePDF} from "./DisclosurePDFs/Dealer/Lease/DealerDisclosurePDF";
import {useDealContext} from "../../contexts/DealContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Popover from "@mui/material/Popover";
import {useModalContext} from "../../contexts/ModalContext";
import CircularProgress from '@mui/joy/CircularProgress';
import {PurchaseDealerDisclosurePDF} from "./DisclosurePDFs/Dealer/Purchase/PurchaseDealerDisclosurePDF";
import {PurchaseCustomerDisclosurePDF} from "./DisclosurePDFs/Customer/Purchase/PurchaseCustomerDisclosurePDF";


let usePDF = () => [{url: '', loading: false}, function(){}]

if (process.env.NODE_ENV !== 'test') {
    import("@react-pdf/renderer")
        .then((mod) => {
            usePDF = mod.usePDF
        });
}

const DealHeader = ( { dealState, setDealStateWithUpdate, isModal } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse, dealColorLight, cloneDealAsPurchaseOrLease } = useDealContext()
    const { setIsDealModalOpen, setIsLocationModalOpen } = useModalContext()
    const [isCreatingDeal, setIsCreatingDeal] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [CustomerPDF, updateCustomerPDF] = usePDF({ document: <CustomerDisclosurePDF deal={dealState}/> });
    const [DealerPDF, updateDealerPDF] = usePDF({ document: <DealerDisclosurePDF deal={dealState}/> });


    useEffect(function updatePDFState() {
        if (dealState.is_purchase) {
            updateCustomerPDF(<PurchaseCustomerDisclosurePDF deal={dealState}/>)
            updateDealerPDF(<PurchaseDealerDisclosurePDF deal={dealState}/>)
        } else {
            updateCustomerPDF(<CustomerDisclosurePDF deal={dealState}/>)
            updateDealerPDF(<DealerDisclosurePDF deal={dealState}/>)
        }

    }, [dealState])

    const car = dealState.car_detail
    const isPurchase = dealState.is_purchase

    const buildFileName = (name) => {
        return `${name}_Disclosure_${car.year}_${car.make}_${car.model}_${buildTrimAndStyleDescriptionName(car.trim, car.style_description)}`
    }

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const createClonedDeal = () => {
        setIsCreatingDeal(true)
        cloneDealAsPurchaseOrLease().then((data) => {
            setIsCreatingDeal(false)
            // window.open(`/deals/${data.data.id}`, '_blank')
            setIsDealModalOpen(data?.data?.id)
        }).catch(() => setIsCreatingDeal(false))
    }

    const cloneIcon = () => {
        let icon;
        if (isCreatingDeal) {
            icon = <CircularProgress variant="soft" size={'sm'}/>
        } else {
            if (isPurchase) {
                icon = <AddOutlinedIcon className={'isPurchase'}/>
            } else {
                icon = <AccountBalanceIcon/>
            }
        }
        return icon
    }

    if (!dealState) return null;

    return (
        <Box>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{ color: dealColorLight()}}
                className={'DealPage-header'}
            >
                <div>
                    <Typography
                        variant="h5"
                        component="div"
                        className={'header-title'}
                    >
                        { isPurchase ? 'PURCHASE ' : 'LEASE '} DEAL
                        {
                            isModal &&
                            <Link to={`/deals/${dealState.id}`} target="_blank" rel="noopener noreferrer" >
                                <OpenInNewOutlinedIcon />
                            </Link>
                        }
                        {
                            !isTabletOrMobile &&
                            <Typography
                                variant="subtitle1"
                                component="div"
                                className={'header-subtitle press-enter'}
                            >
                                {
                                    updateDealResponse?.loading ? 'Saving...' : 'Press Enter to save'
                                }
                            </Typography>
                        }
                    </Typography>
                </div>
                <div>
                    <div
                        onClick={handleClick}
                        className={'header-button'}
                        style={{ background: dealColorLight() }}
                    >
                        Tools
                        <ArrowDropDownIcon/>
                    </div>
                    <Popover
                        onClick={(e) => e.stopPropagation()}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                        className={'deal-header-dropdown-menu-container'}
                    >
                        <Box className={'deal-header-dropdown-menu'}>
                            <a
                                href={CustomerPDF?.url}
                                download={buildFileName('Customer')}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >

                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    <SaveAltIcon
                                        className={classnames('', {
                                            isPurchase: isPurchase
                                        })}
                                    />
                                </span>
                                { CustomerDisclosurePDF.loading ? <span>Loading Document...</span> : <span>Customer PDF</span> }
                            </a>
                            <a
                                href={DealerPDF?.url}
                                download={buildFileName('Dealer')}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >
                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    <SaveAltIcon
                                        className={classnames('', {
                                            isPurchase: isPurchase
                                        })}
                                    />
                                    </span>
                                { DealerDisclosurePDF.loading ? <span>Loading Document...</span> : <span>Dealer PDF</span> }
                            </a>

                            <div
                                onClick={createClonedDeal}
                                className={classnames('header-button', {
                                    isPurchase: isPurchase
                                })}
                            >
                                <span
                                    style={{ marginRight: '20px'}}
                                >
                                    { cloneIcon() }
                                </span>
                                <span>
                                    Create as { isPurchase ? 'Lease ' : 'Purchase '}
                                </span>
                            </div>
                        </Box>
                    </Popover>
                </div>
            </Stack>
        </Box>
    );
};

export default DealHeader;