import React, {useEffect, useState, useRef} from "react";
import useEnterEffect from "../hooks/useEnterEffect";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

export const PopoverInput = ({id, value, onChange, inputProps, ...props}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [textValue, setTextValue] = useState(value)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEnterEffect(() => {
        handleClose()
    }, [anchorEl])

    useEffect(() => {
        onChange(textValue)
    }, [textValue])

    return (
        <React.Fragment>
            <TextField
                tabIndex={anchorEl ? "-1": "0"}
                id={id}
                variant="standard"
                // className="form-control"
                type="text"
                value={value}
                onClick={handleClick}
                style={{
                    textOverflow: 'ellipsis',
                }}
                {...inputProps}
            />
            <Popover
                onClick={(e) => e.stopPropagation()}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    horizontal: 'center',
                    vertical: 'center'
                }}
            >
                <textarea
                    autoFocus={true}
                    className={'PopoverInput-textarea'}
                    id="txtArea"
                    rows="8"
                    cols="40"
                    onChange={(e) => setTextValue(e.target.value)}
                    value={textValue}
                    {...props}
                />
            </Popover>
        </React.Fragment>
    )
}