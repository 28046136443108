import React from 'react'
import {Document, Page, Text, View} from "@react-pdf/renderer";
import {PaymentInformationPDF} from "../../components/PaymentInformationPDF";

import { baseStyles} from "../../styles";
import {AmountDueAtSigningPDF} from "../../components/AmountDueAtSigningPDF";
import {TaxInformationPDF} from "../../components/TaxInformationPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {LenderInfoPDF} from "../../components/LenderInfoPDF";
import {LocationInfoPDF} from "../../components/LocationInfoPDF";
import {ResidualInformationPDF} from "../../components/ResidualInformationPDF";
import {MileageInformationPDF} from "../../components/MileageInformationPDF";


export const CustomerDisclosurePDF = ( { deal } ) => {
    if (!deal || !deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>
                <View style={{
                    ...baseStyles.flexBetween,
                    fontSize: '20px',
                    fontWeight: '600',
                    alignItems: 'center',
                    alignContent: 'center',
                }}>
                    <Text
                        style={{fontSize: '10px'}}
                    >Customer Disclosure</Text>
                    <Text>TrueDesking</Text>
                    <Text
                        style={{fontSize: '10px'}}
                    >{new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}</Text>
                </View>

                <View style={{...baseStyles.header}}>
                    <VehicleInfoPDF deal={deal}/>
                    <LenderInfoPDF deal={deal}/>
                    <LocationInfoPDF deal={deal}/>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <AmountDueAtSigningPDF deal={deal}/>
                        <PaymentInformationPDF deal={deal} />
                        <ResidualInformationPDF deal={deal} />
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <TaxInformationPDF deal={deal}/>
                        <MileageInformationPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}