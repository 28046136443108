import _ from 'lodash'

class IncentiveHelper {
    constructor(incentive) {
        this.incentive = incentive
    }

    get = (path, base) => _.get(base || this.incentive, path)

    feeId = () => this.get(['FeeID'])

    subTitleId = () => this.get(['SubTitleId']).toString()

    name = () => this.get(['Name'])

    amount = () => this.get(['CashAmount'])

    description = () => this.get(['EligibilityDescription'])

    setIncentive = (fees) => {
        const newFees = fees
        newFees.push({
            "Amount": this.amount() * -1,
            "Name": this.name(),
            "Type": "Financed",
            "Base": "Undefined",
            "WholeRoundOption": "Undefined",
            "RoundToOption": "Undefined",
            "LimitType": "Undefined",
            "Handling": "Undefined",
            "DescriptionType": "RegularFee",
            "TaxDeduction": "Undefined",
            "FinanceTaxes": "Yes",
            "CCRPortionFeeTaxed": "Undefined",
            "TitleId": "10012",
            "Id": "10012",
            "SubTitleId": this.subTitleId(),
            "GranularityLevel": "Undefined",
            "FormsData": {}
        })
        return newFees
    }

    createDealerRebate = (amount, name) => {
        return {
            "Amount": amount * -1, // rebates are negative fees
            "Name": name,
            "Type": "Financed",
            "FinanceTaxes": "Yes",
            "TaxIndex": -1,  // -1 means fully taxed
            "Base": "Undefined",
            "WholeRoundOption": "Undefined",
            "RoundToOption": "Undefined",
            "LimitType": "Undefined",
            "Handling": "Undefined",
            "DescriptionType": "Undefined",
            "TaxDeduction": "Undefined",
            "CCRPortionFeeTaxed": "Undefined",
            "TitleId": "10011",
            "Id": "10011",
            "SubTitleId": "10011",
            "GranularityLevel": "Undefined",
            "FormsData": {}
        }
    }
}

export default IncentiveHelper