import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    boxShadow: 24,
    p: 4,
};

export const DealRebateModal = ( {name, description, isOpen, setIsOpen} ) => {
    return (
        <Modal
            open={isOpen}
            onClose={() => setIsOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                style={{
                    ...style,
                    maxWidth: '515px',
                    padding: '31px 57px 60px 56px'
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="left"
                    className={''}
                >
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        className={'DealRebateModal-name'}
                    >
                        {name}
                    </Typography>
                    <Divider className={'divider'} />
                    <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                        className={'DealRebateModal-description'}
                    >
                        {description}
                    </Typography>
                </Stack>
            </Box>
        </Modal>
    )
}