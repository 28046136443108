import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const MileageInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Mileage Information</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text>Mileage Program Allowance (per year)</Text>
                    <Text>{helper.annualMileage()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Additional Puchased Miles (per year)</Text>
                    <Text>{helper.mileage()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Total Additional Miles Charge</Text>
                    <Text>{helper.mileageCharge()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Total Mileage Allowed</Text>
                    <Text>{helper.annualMileage()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Excess Mileage Charge Fee</Text>
                    <Text>{helper.excessMileage()}</Text>
                </View>

            </View>
        </View>
    )
}