import React, {useEffect, useState} from 'react'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';

import {usePricingContext} from "../../contexts/PricingContext";
import Tooltip from "@mui/material/Tooltip";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {useModalContext} from "../../contexts/ModalContext";
import classnames from "classnames";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import useEnterEffect from "../../hooks/useEnterEffect";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {LocationModal} from "../DealPage/LocationModal";
import {useUserContext} from "../../contexts/UserContext";
import {PopoverInput} from "../../components/PopoverInput";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";


const getInitialState = (pricing, style_description, location) => {
    return {
        description: pricing?.description || '',
        msrp: pricing?.msrp || '',
        invoice: pricing?.invoice || null,
        above: pricing?.above || 0,
        back: pricing?.back || 0,
        profit: pricing?.profit || 0,
        car: style_description.car,
        is_purchase: false,
        pricing_id: pricing?.id,
        location: location,
        location_id: location.id
    }
}

export const PricingListRow = ( { pricing, style_description } ) => {
    const { profileState } = useUserContext()
    const default_location = profileState?.default_location
    const [isLocationModalOpen, setIsLocationModalOpen] = useState(false)
    const initialLocation = pricing?.location || default_location
    const [pricingState, setPricingState] = useState(getInitialState(pricing, style_description, initialLocation))
    const [isCreatingLease, setIsCreatingLease] = useState(false)
    const [isCreatingPurchase, setIsCreatingPurchase] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const {
        deletePricing,
        createDeal,
        createPricing,
        updatePricing,
        refreshData
    } = usePricingContext()
    const { setIsDeskingModalOpen, setIsDealModalOpen, isDealModalOpen } = useModalContext()

    const updatePricingState = (field, value) => {
        setPricingState({...pricingState, [field]: value})
    }

    const deletePricingRow = (pricingId) => {
        setIsDeleting(true)
        return deletePricing(pricingId).then(() => {
            setIsDeleting(false)
            refreshData()
        }).catch(() => {
            setIsDeleting(false)
        })
    }

    const updatePricingFunc = (isPurchase) => {
        if (pricing?.id) {
            const nextFunc = isPurchase ? createNewPurchase : createNewLease
            setIsUpdating(true)
            let updateData = pricingState
            updateData.id = pricing.id
            return updatePricing(pricing.id, updateData).then((data) => {
                setIsUpdating(false)
                const pricingId = data.data.id
                return nextFunc(pricingId)
            }).catch(() => {
                setIsUpdating(false)
            })
        }
    }

    const createNewPricing = (isPurchase) => {
        const nextFunc = isPurchase ? createNewPurchase : createNewLease
        return createPricing(pricingState).then((data) => {
            const pricingId = data.data.id
            return nextFunc(pricingId).then(() => {
                setPricingState(
                    getInitialState(null, style_description, default_location)
                )
            })
        })
    }

    const createOrUpdatePricing = (isPurchase) => {
        const pricingFunc = pricing?.id ? updatePricingFunc : createNewPricing
        return pricingFunc(isPurchase)
    }

    const createNewLease = (pricingId) => {
        setIsCreatingLease(true)
        let createData = pricingState
        createData['pricing_id'] = pricingId

        return createDeal(createData).then((data) => {
            setIsDeskingModalOpen(
                {
                    id: data.data.id,
                    onSave: (updateData) =>  setIsDealModalOpen(updateData.id)
                }
            )
            refreshData()
            setIsCreatingLease(false)
        }).catch(() => setIsCreatingLease(false))
    }

    const createNewPurchase = (pricingId) => {
        setIsCreatingPurchase(true)
        let createData = pricingState

        createData['is_purchase'] = true
        createData['pricing_id'] = pricingId

        return createDeal(createData).then((data) => {
            setIsDeskingModalOpen(
                {
                    id: data.data.id,
                    onSave: (updateData) =>  setIsDealModalOpen(updateData.id)
                }
            )
            refreshData()
            setIsCreatingPurchase(false)
        }).catch(() => setIsCreatingPurchase(false))
    }

    const buildId = (field) => {
        return pricing ? `PricingListRow-${field}-${pricing.id}` : `PricingListRow-create-${style_description.id}-${field}`
    }

    const isDisabled = Boolean(!pricingState.msrp)

    useEnterEffect(() => {
        const docId = document.activeElement.id
        if (!isDisabled) {
            if (docId.split('-')[2] === (String(pricing?.id)) ) {
                updatePricingFunc(false)
            }
            if (
                docId.split('-')[1] === 'create' &&
                docId.split('-')[2] === String(style_description.id) &&
                Boolean(!pricing)
            ) {
                createNewPricing(false)
            }
        }
    }, [pricingState])

    const isDefaultLocation = Boolean(pricingState.location.id === default_location.id)
    const isCreating = isCreatingLease || isCreatingPurchase

    return (
        <React.Fragment key={style_description?.id || `${style_description.name}-pricing-new`}>
            {
                pricing?.id &&
                <td>
                    <Tooltip title="Delete" arrow placement={'top'}>
                        <DeleteSharpIcon
                            className={classnames(
                                'action-icon garbage', {
                                    loader: isDeleting
                                }
                            )}
                            onClick={() => deletePricingRow(pricing.id)}
                        />
                    </Tooltip>
                </td>
            }
            <td>
                <PopoverInput
                    id={buildId('description')}
                    value={pricingState.description}
                    onChange={text => updatePricingState('description' , text)}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('msrp')}
                    value={pricingState.msrp}
                    onChange={(value) => updatePricingState('msrp', value)}
                    prefix="$"
                    placeholder={'0'}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('invoice')}
                    prefix="$"
                    value={pricingState.invoice}
                    onChange={(value) => updatePricingState('invoice', value)}
                    allowNull={true}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('above')}
                    prefix="$"
                    value={pricingState.above}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('above', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('back')}
                    prefix="$"
                    value={pricingState.back}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('back', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <CustomCurrencyInput
                    id={buildId('profit')}
                    prefix="$"
                    value={pricingState.profit}
                    defaultValue={0}
                    onChange={(value) => updatePricingState('profit', value)}
                    className={classnames('payment-row_value', {
                        loading: isCreating
                    })}
                />
            </td>
            <td>
                <Tooltip title={'Lease'} arrow placement={'top'}>
                    <AddOutlinedIcon
                        className={classnames(
                            'action-icon', {
                                loader: isCreatingLease,
                                disabled: isDisabled
                            }
                        )}
                        onClick={() => createOrUpdatePricing(false)}
                    />
                </Tooltip>
                <Tooltip title={'Purchase'} arrow placement={'top'}>
                    <AccountBalanceIcon
                        className={classnames(
                            'action-icon purchase', {
                                loader: isCreatingPurchase,
                                disabled: isDisabled
                            }
                        )}
                        onClick={() => createOrUpdatePricing(true)}
                    />
                </Tooltip>
                {
                    // Existing Pricing Section
                    pricing?.id &&
                    <Tooltip title={'Deals'} arrow placement={'top'}>
                        <Link to={`/deals?pricing_id=${pricing.id}`} target="_blank">
                            <LibraryBooksIcon
                                className={'action-icon'}
                            />
                        </Link>
                    </Tooltip>

                }
                {
                    // New Pricing Section
                    !pricing?.id &&
                    <Tooltip title={'Set Location'} arrow placement={'top'}>
                        {
                            isDefaultLocation ?
                                <LocationOnIcon
                                    className={'action-icon'}
                                    onClick={() => setIsLocationModalOpen(true)}
                                /> :
                                <EditLocationIcon
                                    className={'action-icon'}
                                    onClick={() => setIsLocationModalOpen(true)}
                                />
                        }
                    </Tooltip>
                }
            </td>

            {
                isLocationModalOpen &&
                <LocationModal
                    open={isLocationModalOpen}
                    initialLocation={pricingState?.location}
                    onClose={() => setIsLocationModalOpen(false)}
                    selectLocation={(location) => {
                        updatePricingState('location_id', location.id)
                        updatePricingState('location', location)
                        setIsLocationModalOpen(false)
                      }
                    }
                />
            }
        </React.Fragment>
    )
}