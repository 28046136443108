import React, { useEffect } from "react";

import {Loginpage} from "./pages/LoginPage/LoginPage";
import {PricingContextProvider} from "./contexts/PricingContext";
import {PricingListPage} from "./pages/PricingListPage/PricingListPage";
import {DealPage} from "./pages/DealPage/DealPage";
import {DeskingPage} from "./pages/DeskingPage/DeskingPage";
import {AuthContextProvider} from "./contexts/AuthContext";
import {Outlet} from "react-router-dom";
import {useLocation} from "react-router-dom";
import {Navigate} from "react-router";
import {HomePage} from "./pages/HomePage/HomePage";
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {UserContextProvider} from "./contexts/UserContext";
import {DealContextProvider} from "./contexts/DealContext";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import {ListDealsPage} from "./pages/ListDealsPage/ListDealsPage";
import {ListDealsContextProvider} from "./contexts/ListDealsContext";
import {ModalContextProvider} from "./contexts/ModalContext";
import {ErrorBoundaryLogger} from "./components/ErrorBoundaryLogger";
import {ToastContainer} from "react-toastify";
import {UIContextProvider} from "./contexts/UIContext";
import {CalculatePage} from "./pages/CalculatePage/CalculatePage";
import {CalcContextProvider} from "./contexts/CalcContext";
import {toTitleCase} from "./utils/utils";

function NoMatch() {
    let location = useLocation();

    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
}

const DocumentTitleProvider = ({ children }) => {
    let location = useLocation();
    useEffect(function updateDocumentTitle(){
        const suffix = toTitleCase(location.pathname.split('/').join(" "))
        document.title = `TrueDesking - ${suffix}`
    }, [location]);

    return children
}

export const routes = [
    {
        element: (
            <React.Fragment>
                <DocumentTitleProvider>
                    <UIContextProvider>
                        <ToastContainer
                            autoClose={2500}
                        />
                        <ErrorBoundaryLogger>
                            <DndProvider backend={HTML5Backend}>
                                <ModalContextProvider>
                                    <AuthContextProvider>
                                        <Outlet/>
                                    </AuthContextProvider>
                                </ModalContextProvider>
                            </DndProvider>
                        </ErrorBoundaryLogger>
                    </UIContextProvider>
                </DocumentTitleProvider>
            </React.Fragment>
        ),
        children: [
            {
                element: (
                    <UserContextProvider>
                        <CalcContextProvider>
                            <HomePage/>
                        </CalcContextProvider>
                    </UserContextProvider>
                ),
                path: "/",
                children: [
                    { index: true, element: <Navigate to="/calculate" replace /> },
                    {
                        path: "/calculate",
                        element: <CalculatePage/>,
                    },
                    {
                        path: "/deals",
                        element: <ListDealsContextProvider><ListDealsPage/></ListDealsContextProvider>,
                    },
                    {
                        path: "/pricing/:make",
                        element: (
                            <PricingContextProvider>
                                <PricingListPage/>
                            </PricingContextProvider>
                        ),
                    },
                    {
                        path: "/deals/:id",
                        element: <DealContextProvider><DealPage isDealPage={true}/></DealContextProvider>,
                    },
                    {
                        path: "/account/profile",
                        element: <ProfilePage/>
                    },
                    {
                        path: "/*",
                        element: <NoMatch />
                    },
                ]
            },
            {
                path: '/login',
                element: <Loginpage/>
            },
        ]
    },
]