import axios from "axios";
import {BASE_URL, URLS} from "./urls";
import Cookies from "js-cookie";
import TokenService from "./utils/TokenService";
import CookieService from "./utils/CookieService";
import {toast} from "react-toastify";

const version = process.env.REACT_APP_VERSION

const instance = axios.create({
    withCredentials: true,
    baseURL: BASE_URL,
    // timeout: 5000,
    headers: {
        'X-CSRFToken': Cookies.get('csrftoken'),
        'X-Version': version
    }
});

// Set Auth Token
instance.interceptors.request.use((config) => {
        config.headers['X-CSRFToken'] =  Cookies.get('csrftoken')
        if (TokenService.getLocalAccessToken()) {
            config.headers['Authorization'] = `Bearer ${TokenService.getLocalAccessToken()}`
        }
        return config
    }
)

// Check for RefreshClient header
instance.interceptors.response.use((response) => {
    if(response?.headers) {
        if (response?.headers?.refreshclient === 'true') {
            CookieService.setItem('refreshClient', true)
        } else {
            CookieService.setItem('refreshClient', false)
        }
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});

instance.interceptors.response.use((res) => {
    return res;
}, (error) => {
    if (error.response?.headers?.logoutuser === "true")  {
        TokenService.removeTokens()
    }
    return Promise.reject(error);
});

// Set Auth Refresh Tokens
instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        const isLoginError = Boolean(originalConfig.url === "/accounts/login" && err.response)
        const isRefreshError = Boolean(originalConfig.url === "/accounts/token/refresh" && err.response)
        const isUnauthorized = Boolean(err.response.status === 401 || err.response.status === 403)

        if (!isLoginError) {
            // Access Token was expired
            if (isUnauthorized && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    if (TokenService.getLocalRefreshToken()) {
                        const rs = await instance.post(URLS.refreshToken(), {
                            refresh: TokenService.getLocalRefreshToken()
                        });

                        const { access } = rs.data;
                        TokenService.setLocalAccessToken(access);

                        return instance(originalConfig);
                    } else {
                        TokenService.removeTokens()
                        if (window.location.pathname !== '/login') {
                            window.location.replace("/login");
                        }
                        return Promise.reject(err);
                    }

                } catch (_error) {
                    TokenService.removeTokens()
                    if (window.location.pathname !== '/login') {
                        window.location.replace("/login");
                    }
                    return Promise.reject(_error);
                }
            }
        }


        if (!isLoginError && !isRefreshError && !isUnauthorized) {
            let message = err.response?.data?.detail

            message ||= "Something went wrong. Please try again soon."
            toast.error(message)
        }

        return Promise.reject(err);
    }
);

export default instance;