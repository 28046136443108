import React, {useState} from "react";
import Autocomplete from "react-google-autocomplete";
import classnames from "classnames";
import {parseGoogleAddressData} from "../utils/utils";
import Button from "react-bootstrap/Button";
import CircularProgress from "@mui/joy/CircularProgress";

export const CreateLocationInput = ( { onSave, isSaving } ) => {
    const [addressState, setAddressState] = useState(null)

    const saveAddress = () => {
        onSave(addressState)
        setAddressState(null)
    }

    return (
        <div className={'CreateLocationInput'}>
            <label>Search Locations</label>
            <Autocomplete
                placeholder={''}
                className={classnames('CreateLocationInput_input', {
                    disabled: !addressState
                })}
                style={{zIndex: "inherit"}}
                inputAutocompleteValue={addressState}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) => {
                    setAddressState(parseGoogleAddressData(place.address_components))
                }}
                options={{
                    componentRestrictions: {country: ["us", "ca"]},
                    fields: ["address_components", "geometry"],
                    types: ["address"],
                }}
            />
            <div>
                <Button
                    className={classnames('CreateLocationInput_button', {
                        disabled: !addressState
                    })}
                    onClick={saveAddress}
                    variant="contained"
                    disabled={!addressState}
                >
                    {
                        isSaving ? <CircularProgress variant="soft" size={'sm'}/> : 'Save Location'
                    }
                </Button>
            </div>
        </div>
    )
}