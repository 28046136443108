import React from 'react'
import classnames from 'classnames'
import DealHeader from "./DealHeader";
import {DealPageFees} from "./DealPageFees";
import {DealPagePayment} from "./DealPagePayment";
import {useDealContext} from "../../contexts/DealContext";
import {DealPageRebates} from "./DealPageRebates";

import Box from "@mui/material/Box";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Stack from "@mui/material/Stack";
import {DealPageInfo} from "./DealPageInfo";
import './DealPage.scss'
import {LocationModal} from "./LocationModal";
import {useModalContext} from "../../contexts/ModalContext";
import {DeskingModal} from "../PricingListPage/DeskingModal";

export const DealPage = ( { isModal } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { dealState, setDealStateWithUpdate, dealPageRef, updateDealResponse } = useDealContext()
    const {
        isLocationModalOpen,
        setIsLocationModalOpen,
        isDeskingModalOpen,
        setIsDeskingModalOpen,
    } = useModalContext()

    const saveDealLocation = (location) => {
        setIsLocationModalOpen(false)
        setDealStateWithUpdate({location_id: location.id, location: location}, true)
    }

    if (!dealState) return null;

    const isPurchase = dealState.is_purchase

    return (
        <Box
            id={"DealPage"}
            className={classnames('', {
                isPurchase: isPurchase
            })}
        >
            <div ref={dealPageRef}/>
            <DealHeader
                isModal={isModal}
                dealState={dealState}
                setDealStateWithUpdate={setDealStateWithUpdate}
            />

            <DealPageInfo dealState={dealState}  setDealStateWithUpdate={setDealStateWithUpdate}/>
            <DealPagePayment isDealPage={true}/>

            <Stack
                style={{
                    display: isTabletOrMobile ? 'flex' : 'block',
                }}
                direction={isTabletOrMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
            >
                <DealPageRebates dealState={dealState} setDealStateWithUpdate={setDealStateWithUpdate}/>

                <DealPageFees dealState={dealState} setDealStateWithUpdate={setDealStateWithUpdate}/>
            </Stack>
            {
                isLocationModalOpen &&
                <LocationModal
                    open={isLocationModalOpen}
                    setIsOpen={() => setIsLocationModalOpen(false)}
                    onClose={() => setIsLocationModalOpen(false)}
                    initialLocation={dealState.location}
                    selectLocation={saveDealLocation}
                    isSaving={updateDealResponse?.loading}
                />
            }
        </Box>
    )
}