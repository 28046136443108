import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerDownPaymentBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Down Payment Breakdown</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text>Cash Down Payment</Text>
                    <Text>{helper.cashDueOnDelivery()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Customer Rebates</Text>
                    <Text>{helper.totalRebatesAmount()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Total Down Payment</Text>
                    <Text>{helper.totalDownPayment()}</Text>
                </View>

                <View style={{...baseStyles.sumRow}}>
                    <Text>Sales Subtotal</Text>
                    <Text>{helper.salesSubtotal()}</Text>
                </View>

            </View>
        </View>
    )
}