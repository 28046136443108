import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const CapitalizedCostBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Capitalized Cost Breakdown</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text
                        style={baseStyles.flex}
                    >
                        Vehicle Cost
                    </Text>
                    <Text>
                        {deal.cost}
                    </Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Profit</Text>
                    <Text>{helper.vehicleProfit()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Total Selling Price</Text>
                    <Text>{helper.sellingPrice()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Acquisition Fee</Text>
                    <Text>{helper.acquisitionFeeAmount()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Fees and Insurance</Text>
                    <Text>{helper.feesAndInsurance()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Inception Fees (Capitalised)</Text>
                    <Text>{helper.inceptionFeesAddedToCap()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Cash as Cap Reduction</Text>
                    <Text>{helper.capReductionCash()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Trade as Cap Reduction</Text>
                    <Text>{helper.tradeAsCapReduction()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Trade as Negative Equity</Text>
                    <Text>{helper.negativeTradeEquity()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Rebate as Cap Reduction</Text>
                    <Text>{helper.rebateAsCapReduction()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Capitalised Cost</Text>
                    <Text>{helper.capitalizedCost()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Capitalised Taxes</Text>
                    <Text>{helper.cappedTaxes()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween, ...baseStyles.boldTopBorder}}>
                    <Text>Total Capital Cost</Text>
                    <Text>{helper.totalCapitalCost()}</Text>
                </View>
            </View>
        </View>
    )
}