import React from 'react'
import classnames from 'classnames';

import QuoteHelper from "../../utils/QuoteHelper";
import {useDealContext} from "../../contexts/DealContext";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {useState} from "react";
import {SkeletonLoader} from "../../components/SkeletonLoader";
import {useModalContext} from "../../contexts/ModalContext";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";

const DeskingTableRowCell = ( { quote, isSelected, isPurchase }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDeal, updateDealResponse, setDealStateWithUpdate, onSave, dealState } = useDealContext()
    const { setIsDeskingModalOpen } = useModalContext()
    const [selected, setSelected] = useState(false)

    const saveQuote = (quote) => {
        setSelected(true)
        const helper = new QuoteHelper(null, quote)
        const lender = helper.lender()
        const term = helper.term()
        return updateDeal(dealState.id, {lender: lender, term: term}).then((data) => {
            const updateData = data?.data
            onSave && onSave(updateData)
            setSelected(false)
            setIsDeskingModalOpen(null)
        }).catch(() => {
            setSelected(false)
        })

    }

    if (!quote) {
        return <td className={'disabled'}></td>
    }
    const helper = new QuoteHelper(null, quote)

    return (
        <SkeletonLoader loading={updateDealResponse.loading && selected}>
            <td
                onClick={() => {
                    if (!updateDealResponse.loading) {
                        saveQuote(quote)
                    }
                }}
                className={'quote'}
            >
                <div
                    className={classnames('info', {
                        selected: isSelected,
                        isPurchase: isPurchase,
                    })}
                >
                    {
                        !isTabletOrMobile && <DescriptionOutlinedIcon/>
                    }
                    <div className={'info-payment'}>
                        <Typography fontWeight={700} variant="div">
                            ${isPurchase ? helper.monthlyPayment() : helper.advancePayment()}
                        </Typography>
                        {
                            !isTabletOrMobile &&
                            <Typography className={'month'}>
                                /Month
                            </Typography>
                        }
                    </div>
                </div>

            </td>
        </SkeletonLoader>
    )
}

export const DeskingTableRow = ( { term, quotes, lenders, dealId, dealState } ) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const isSelected = (term, lender) => {
        return Boolean(
            dealState &&
            String(dealState.term) === String(term) &&
            dealState.lender === lender
        )
    }

    return (
        <tr>
            <td className={'term'}>
                <Typography
                    fontWeight={400}
                    fontSize={20}
                    variant="div"
                    gutterBottom>
                    {term}
                </Typography>
                {
                    !isTabletOrMobile &&
                    <Typography fontWeight={400} variant="subtitle1" gutterBottom>
                        MONTHS
                    </Typography>
                }
            </td>
            {
                lenders.map( (lender, index) => {
                    const quote = quotes[lender]
                    return <DeskingTableRowCell
                        isPurchase={dealState.is_purchase}
                        dealId={dealId}
                        quote={quote}
                        isSelected={isSelected(term, lender)}
                        key={index}
                    />
                })
            }
        </tr>
    )
}