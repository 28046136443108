import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerTaxInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Tax Information</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text>Finance Tax {helper.taxRate()}</Text>
                    <Text>{helper.taxAmount()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Tire Tax</Text>
                    <Text>{helper.tireFeeTax()}</Text>
                </View>
            </View>
        </View>
    )
}