import classnames from "classnames";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from '@mui/icons-material/Star';
import React from "react";

export const LocationItem = ( {
                                  location,
                                  deleteLocation,
                                  isSelected,
                                  setSelectedLocation,
                                  isInitialLocation
}) => {

    const isDefaultLocation = location.is_default_location
    return (
        <div
            className={classnames('LocationItem', {
                selected: isSelected
            })}
        >
            <div>
                <Checkbox
                    className={'LocationItem_checkbox'}
                    onChange={() => setSelectedLocation(location)}
                    checked={isSelected}
                    disabled={isSelected}
                />
            </div>
            <div className={'LocationItem_address'}>
                <span>{location.city}, </span>
                <span>{location.county}, </span>
                <span>{location.state}</span>
            </div>
            <div>
                {
                    !isInitialLocation && !isDefaultLocation &&
                    <DeleteIcon
                        onClick={() => deleteLocation(location.id)}
                        className={classnames('LocationItem-icon delete', {
                            selected: isSelected
                        })}
                    />
                }
                {
                    isDefaultLocation &&
                    <StarIcon
                        className={classnames('LocationItem-icon ', {
                            selected: isSelected
                        })}
                    />
                }
            </div>
        </div>
    )
}