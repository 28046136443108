import React, {useState, useEffect} from "react";
import Stack from "@mui/material/Stack";
import {useMediaQuery} from "react-responsive";
import classnames from "classnames";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {useModalContext} from "../../contexts/ModalContext";
import Tooltip from "@mui/material/Tooltip";
import {CalcSelectCar} from "./CalcSelectCar";
import {LEASE_DEAL_LIGHT, PURCHASE_DEAL_LIGHT} from "../../contexts/DealContext";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import {useCalcContext} from "../../contexts/CalcContext";
import useEnterEffect from "../../hooks/useEnterEffect";
import {useLocation} from "react-router-dom";


export const CalcPageInfo = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    let location = useLocation();
    const [isCreatingLease, setIsCreatingLease] = useState(false)
    const [isCreatingPurchase, setIsCreatingPurchase] = useState(false)
    const {
        calcState,
        setCalcState,
        createDeal,
        createDealResponse,
    } = useCalcContext()

    const {
        setIsLocationModalOpen,
        setIsDealModalOpen,
        setIsDeskingModalOpen,
        isDealModalOpen
    } = useModalContext()

    const createNewLease = () => {
        setIsCreatingLease(true)

        return createDeal(calcState).then((data) => {
            setIsCreatingLease(false)
        }).catch(() => setIsCreatingLease(false))
    }

    const createNewPurchase = () => {
        setIsCreatingPurchase(true)
        let createData = structuredClone(calcState)

        createData.is_purchase = true

        return createDeal(createData).then((data) => {
            setIsCreatingPurchase(false)
        }).catch(() => setIsCreatingPurchase(false))
    }

    useEffect(function openDeskingModal() {
        if (createDealResponse?.data?.data && !createDealResponse?.loading && !createDealResponse.error) {
            const id = createDealResponse?.data?.data.id
            setIsDeskingModalOpen(
                {
                    id: id,
                    onSave: (updateData) =>  setIsDealModalOpen(updateData.id)
                }
            )
        }
    }, [createDealResponse])


    const isCalculatePath = location.pathname.split('/')[1] === 'calculate'

    useEnterEffect(() => {
        if (
            calcState.car &&
            calcState.msrp &&
            calcState.cost &&
            !isDealModalOpen &&
            isCalculatePath
        ) {
            createNewLease()
        }
    }, [calcState, isDealModalOpen])


    const calcLocation = calcState.location
    const isPurchase = calcState.is_purchase

    const isLoading = createDealResponse?.loading
    const isDisabled = !calcState.msrp || !calcState.cost

    return (
        <Stack
            style={{ width: '100%' }}
            direction={isTabletOrMobile ? 'column' : 'row'}
            alignItems="center"
        >
            <Box className={'CalcPageInfo_box'}
            >
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={classnames('header location-info', {
                        isPurchase: isPurchase
                    })}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    <span> 01 Select Car</span>
                </Typography>
                <Divider className={'divider'} sx={{ marginBottom: '10px'}}/>
                <CalcSelectCar/>
            </Box>
            <Box className={'CalcPageInfo_box'}>
                <div>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className={classnames('header location-info', {
                            isPurchase: isPurchase
                        })}
                        sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            marginBottom: '10px',
                        }}
                    >
                        <span>02 Choose Location</span>
                    </Typography>
                    <Divider className={'divider'}/>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            marginTop: '10px',
                            color: '#666'
                        }}
                    >
                        { calcLocation.county }, {' '}
                        { calcLocation.state }
                    </Typography>
                    <Button
                        className={'dealPage-button'}
                        style={{ background: LEASE_DEAL_LIGHT }}
                        onClick={setIsLocationModalOpen}
                    >
                        Edit Location
                    </Button>

                </div>
            </Box>
            <Box className={classnames("CalcPageInfo_box", {
                mobile: isTabletOrMobile
            })}>
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                    }}
                >
                    03 Vehicle Pricing
                </Typography>
                <Divider className={'divider'}/>

                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        MSRP:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={calcState.msrp}
                        onChange={(value) => setCalcState({...calcState, msrp: value})}
                        allowNull={true}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Cost:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        placeholder={'0'}
                        value={calcState.cost}
                        onChange={(value) => setCalcState({...calcState, cost: value})}
                        allowNull={true}
                    />
                </div>
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Profit:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value', {
                            loading: isLoading
                        })}
                        prefix="$"
                        value={calcState.profit}
                        onChange={(value) => setCalcState({...calcState, profit: value})}
                    />
                </div>
                <Divider className={'divider'} />
                <div
                    className={'payment-row'}
                >
                    <span
                        className={'payment-row_name'}
                    >
                        Selling Price:
                    </span>
                    <CustomCurrencyInput
                        className={classnames('payment-row_value disabled', {
                            loading: isLoading
                        })}
                        value={(Number(calcState.cost) + Number(calcState.profit))}
                        disabled={true}
                    />
                </div>
                <Divider className={'divider'} />
                <Tooltip title="Click or Press Enter to Calculate" arrow placement={'bottom'}>
                    <Button
                        className={classnames('dealPage-button', {
                            isDisabled: isDisabled
                        })}
                        style={{ background: LEASE_DEAL_LIGHT }}
                        onClick={createNewLease}
                        disabled={isDisabled}
                    >
                        {
                            isCreatingLease ? 'Calculating...' : 'Calculate for lease'
                        }
                        {
                            isCreatingLease ? null: <KeyboardReturnIcon/>
                        }
                    </Button>
                </Tooltip>

                <Tooltip title="Click to Calculate" arrow placement={'bottom'}>
                    <Button
                        className={classnames('dealPage-button', {
                            isDisabled: isDisabled
                        })}
                        style={{
                            background: '#FFFFFF',
                            color: PURCHASE_DEAL_LIGHT,
                            border: `1px solid`
                        }}
                        onClick={createNewPurchase}
                        disabled={isDisabled}
                    >
                        {
                            isCreatingPurchase ? 'Calculating...' : 'Calculate for purchase'
                        }
                    </Button>
                </Tooltip>
            </Box>
        </Stack>
    )
}