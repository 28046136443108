import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {baseStyles} from "../styles";

export const LocationInfoPDF = ( { deal } ) => {

    const location = deal.location
    return (
        <View>
            <Text>
                <Text
                    style={{...baseStyles.bold}}
                >
                    State:
                </Text>
                <Text> { location.state } </Text>

                <Text
                    style={{...baseStyles.bold}}
                >
                    County:
                </Text>
                <Text> { location.county } </Text>

                <Text
                    style={{...baseStyles.bold}}
                >
                    City:
                </Text>
                <Text> { location.city } </Text>
            </Text>
        </View>
    )
}