import React from 'react'
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {buildStyleDescriptionName} from "../../utils/utils";
import {useCalcContext} from "../../contexts/CalcContext";


export const CalcSelectCar = () => {
    const {
        carState,
        setCarState,
    } = useCalcContext()

    const renderTrim = (trim) => {
        return (
            <MenuItem key={`${trim.Trim}-${trim.StyleDescription}`} value={trim}>
                <div className={'trim-container'}>
                    <div className={'trim-name'}>{trim.Trim}</div>
                    <div className={'sd-name'}>
                        {buildStyleDescriptionName(trim.Trim, trim.StyleDescription)}
                    </div>
                </div>
            </MenuItem>
        )
    }

    const { makes, models, trims } = carState

    return (
        <Box>
            <Typography
                variant="h5"
                component="div"
                className={'header-title'}
                sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    // marginBottom: '10px',
                }}
            >
                <div className={'select-row'}>
                    <label>Year</label>
                    <Select
                        className={'Select'}
                        labelId="pricing-select-year"
                        id="year-select"
                        value={carState.year}
                        label="Year"
                        onChange={(e) => {
                            const year = e.target.value
                            setCarState({
                                ...carState,
                                year: year,
                                make: '',
                                model: '',
                                trim: '',
                            })
                        }}
                    >
                        <MenuItem value={'2023'}>2023</MenuItem>
                        <MenuItem value={'2024'}>2024</MenuItem>
                        <MenuItem value={'2025'}>2025</MenuItem>
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Brand</label>
                    <Select
                        className={'Select'}
                        value={carState.make}
                        disabled={!carState.year}
                        onChange={(e) => {
                            const make = e.target.value
                            setCarState({
                                ...carState,
                                make: make,
                                model: '',
                                trim: '',
                            })
                        }}
                    >
                        {
                            makes.map((make) => <MenuItem key={make.Make} value={make.Make}>{make.Make}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Model</label>
                    <Select
                        className={'Select'}
                        value={carState.model}
                        disabled={!carState.make}
                        onChange={(e) => {
                            const model = e.target.value
                            setCarState({
                                ...carState,
                                model: model,
                                trim: ''
                            })
                        }}
                    >
                        {
                            models?.map((model) => <MenuItem key={model.Model} value={model.Model}>{model.Model}</MenuItem>)
                        }
                    </Select>
                </div>
                <div className={'select-row'}>
                    <label>Trim</label>
                    <Select
                        className={'Select'}
                        value={carState.trim}
                        disabled={!carState.model}
                        onChange={(e) => {
                            const trim = e.target.value

                            setCarState({
                                ...carState,
                                trim: trim,
                                style_description: trim.StyleDescription,
                            })
                        }}
                    >
                        {
                            trims.map(trim => renderTrim(trim))
                        }
                    </Select>
                </div>
            </Typography>
        </Box>
    )
}