import React from 'react'
import Box from "@mui/material/Box";

import './CalculatePage.scss'
import CalculateIcon from '@mui/icons-material/Calculate';
import {CalcPageInfo} from "./CalcPageInfo";
import classnames from "classnames";
import {LocationModal} from "../DealPage/LocationModal";
import {useModalContext} from "../../contexts/ModalContext";
import {useUserContext} from "../../contexts/UserContext";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useCalcContext} from "../../contexts/CalcContext";
import {LEASE_DEAL_LIGHT} from "../../contexts/DealContext";
import Button from "@mui/material/Button";
import ReplayIcon from '@mui/icons-material/Replay';

export const CalculatePage = () => {
    const { profileState } = useUserContext()
    const { calcState, setCalcState, resetCalcState } = useCalcContext()
    const {
        isLocationModalOpen,
        setIsLocationModalOpen,
    } = useModalContext()

    const saveLocation = (location) => {
        setCalcState({...calcState, location_id: location.id, location: location}, false)
        setIsLocationModalOpen(false)
    }

    const isPurchase = calcState.is_purchase
    const default_location = profileState.default_location

    return (
        <Box id={"CalculatePage"}
             className={classnames('', {
                 isPurchase: isPurchase
             })}
        >
            <Stack
                justifyContent={'center'}
                style={{ width: '100%' }}
            >
                <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className={'header'}
                    sx={{
                        fontSize: '24px',
                        fontWeight: '400',
                        marginBottom: '10px',
                        color: "#666666"
                    }}
                >
                    <CalculateIcon/> Calculate Deal
                    <Button
                        className={'calc-reset-button'}
                        style={{ background: LEASE_DEAL_LIGHT }}
                        onClick={resetCalcState}
                    >
                        Reset <ReplayIcon/>
                    </Button>
                </Typography>


                <CalcPageInfo/>
            </Stack>

            {
                isLocationModalOpen &&
                <LocationModal
                    open={isLocationModalOpen}
                    setIsOpen={() => setIsLocationModalOpen(false)}
                    onClose={() => setIsLocationModalOpen(false)}
                    initialLocation={calcState.location || default_location}
                    selectLocation={saveLocation}
                />
            }
        </Box>
    )
}