import React from "react";
import {useDealContext} from "../../contexts/DealContext";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CurrencyInput from "react-currency-input-field";
import Checkbox from "@mui/material/Checkbox";
import FeeHelper from "../../utils/FeeHelper";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import {useMediaQuery} from "react-responsive";
import useFeeStateHook from "../../hooks/useFeeStateHook";

export const CustomDealPageRebateItem = ({ fee }) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse } = useDealContext()
    const [rebateState, setRebateState] = useFeeStateHook(fee)

    const feeHelper = new FeeHelper(rebateState)
    const selected = feeHelper.isSelected()

    const isLoading = updateDealResponse?.loading

    return (
        <TableRow key={feeHelper.uniqueId()}>
            <TableCell align="left">
                {feeHelper.name()}
            </TableCell>
            <TableCell align="right">
                <CurrencyInput
                    className={'payment-row_value disabled'}
                    prefix="$"
                    value={feeHelper.absoluteAmount()}
                />
            </TableCell>
            {
                !isTabletOrMobile &&
                <React.Fragment>
                    <TableCell align="right">
                        <Checkbox
                            className={'payment-row_value'}
                            onChange={(e) => {
                                const newType = selected ? 'PaidOutsideOfClosing' : 'Financed'
                                const newDescriptionType = selected ? 'RegularFee' : 'Rebate'
                                setRebateState({...rebateState, Type: newType, DescriptionType: newDescriptionType})
                            }}
                            checked={selected}
                            disabled={isLoading}
                        />
                    </TableCell>
                </React.Fragment>
            }
        </TableRow>
    )
}