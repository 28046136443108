import React from "react";
import {useUserContext} from "../../contexts/UserContext";
import {LocationsTable} from "../../components/LocationsTable";
import Box from "@mui/material/Box";

import './ProfilePage.scss'
import {ChangePassword} from "./ChangePassword";


export const ProfilePage = () => {
    const { profileState, updateProfile, updateProfileResponse } = useUserContext()
    const default_location = profileState?.default_location

    if (!default_location) {
        return null
    }

    const setAsDefaultLocation = (location) => {
        return updateProfile(
            {
                ...profileState,
                default_location: null,
                default_location_id: location.id
            }
        )
    }

    return (
        <Box id="ProfilePage">
            <Box className={'ProfilePage_container'}>
                <LocationsTable
                    initialLocation={default_location}
                    selectLocation={setAsDefaultLocation}
                    isDealLocation={false}
                    isSaving={updateProfileResponse?.loading}
                />
                <ChangePassword/>
            </Box>
        </Box>
    )
}