// {
//     "Id": "41337",
//     "Tax": 0,
//     "Name": "Cdtfa Tire Fee",
//     "Type": "Upfront",
//     "Amount": 7,
//     "TaxRate": 0,
//     "TitleId": "41337",
//     "Category": "Registration",
//     "TaxIndex": 0,
//     "FormsData": {
//     "Name": "TIRE_FEE",
//         "Category": "Other",
//         "VendorName": "",
//         "DisplayName": ""
// },
//     "CostPerDay": 0,
//     "SourceType": null,
//     "SubTitleId": null,
//     "UpfrontTax": 0,
//     "RollupTitle": "",
//     "FinanceTaxes": "No",
//     "TaxDeduction": "No",
//     "CostPerPeriod": 0.22,
//     "RollupTitleId": "",
//     "CapitalizedTax": 0,
//     "DescriptionType": "RegularFee",
//     "GranularityLevel": "Standalone",
//     "CCRPortionFeeTaxed": "No",
//     "PaidOutsideOfClosingTax": 0
// }

export const PAID_OUTSIDE_OF_CLOSING_TYPE = 'PaidOutsideOfClosing'
export const UPFRONT_TYPE = 'Upfront'
export const FINANCED_TYPE = 'Financed'

class FeeHelper {
    constructor(fee) {
        this.fee = fee
    }

    name = () => this.fee.Name

    amount = () => this.fee.Amount

    type = () => this.fee.Type

    absoluteAmount = () => Math.abs(this.amount())

    negativeAmount = () => -this.absoluteAmount()

    id = () => this.fee['Id']

    titleId = () => this.fee['TitleId']

    uniqueId = () => `${this.id()}-${this.titleId()}-${this.name()}-${this.amount()}`

    isSelected = () => this.type() !== PAID_OUTSIDE_OF_CLOSING_TYPE

    isFinanced = () => this.type() === 'Financed'

    isUpfront = () => this.type() === 'Upfront'

}

export default FeeHelper