import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import React, {useState} from "react";
import IncentiveHelper from "../../utils/IncentiveHelper";
import QuoteHelper from "../../utils/QuoteHelper";
import {useDealContext} from "../../contexts/DealContext";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import {useMediaQuery} from "react-responsive";
import {mediaQueryForMobile} from "../HomePage/HomePage";
import classnames from "classnames";
import CustomCurrencyInput from "../../components/CustomCurrencyInput";

const initialState = {
    Name: '',
    Amount: null,
    Type: 'PaidOutsideOfClosing',
}

export const CreateRebateItem = ({dealState, setDealStateWithUpdate}) => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { updateDealResponse } = useDealContext()
    const [state, setState] = useState(initialState)

    const incentiveHelper = new IncentiveHelper()

    useDidMountEffect(() => {
        let newDealState = structuredClone(dealState)
        let allFees = newDealState.selected_quote['Quote']['Fees']

        allFees = allFees.filter(
            (fee) => ((fee['Amount'] !== state.Amount ) && (fee['Name'] !== state.Name))
        )

        if (state.Type !== 'PaidOutsideOfClosing') {
            allFees.push(incentiveHelper.createDealerRebate(state.Amount, state.Name))
        }

        newDealState.selected_quote['Quote']['Fees'] = allFees
        setDealStateWithUpdate(newDealState, true)
        setState(initialState)
    }, [state.Type])


    const isLoading = updateDealResponse?.loading

    return (
        <TableRow key={'10011'}>
            <TableCell align="left">
                <input
                    className=""
                    type="text"
                    value={state.Name}
                    placeholder={'Add a custom rebate'}
                    onChange={(e) => setState({...state, Name: e.target.value})}
                />
            </TableCell>
            <TableCell align="right">
                <CustomCurrencyInput
                    onChange={(value) => setState({...state, Amount: value})}
                    className={classnames('payment-row_value custom-fee-input', {
                        loading: isLoading
                    })}
                    placeholder={'0'}
                    value={state.Amount}
                />
            </TableCell>
            <TableCell align="right">
                <Checkbox
                    className={'payment-row_value'}
                    onChange={(e) => {
                        const type = Boolean(e.target.value) ? 'Financed' : 'PaidOutsideOfClosing'
                        setState({...state, Type: type})
                    }}
                    checked={state.Type !== 'PaidOutsideOfClosing'}
                    disabled={isLoading || !state.Name || !state.Amount}
                />
            </TableCell>
        </TableRow>
    )
}