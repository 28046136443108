import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerPriceBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Price Breakdown</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text>Vehicle Cost</Text>
                    <Text>{deal.cost}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Vehicle Profit</Text>
                    <Text>{helper.vehicleProfit()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Customer Rebate (Disc)</Text>
                    <Text>{helper.totalRebatesAmount()}</Text>
                </View>

                <View style={{...baseStyles.sumRow}}>
                    <Text>Total Cash Price</Text>
                    <Text>{helper.totalCashPrice()}</Text>
                </View>
            </View>
        </View>
    )
}