import QuoteHelper from "../../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../../styles";

export const PurchaseDealerPaymentInformationPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    return (
        <View style={{...baseStyles.marginRight, ...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Payment Information</Text>
            </View>

            <View style={baseStyles.tablePadding}>
                <View style={baseStyles.flexBetween}>
                    <Text>Total COD</Text>
                    <Text>{helper.cashDueOnDelivery()}</Text>
                </View>

                <View style={baseStyles.flexBetween}>
                    <Text>Monthly Payment</Text>
                    <Text>{helper.monthlyPayment()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Term:</Text>
                    <Text>{helper.loanTerm()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Annual Percentage Rate (APR)</Text>
                    <Text>{helper.apr()}</Text>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <Text>Days to 1st Payment</Text>
                    <Text>{helper.daysTo1stPmt()}</Text>
                </View>

                {/*<View style={{...baseStyles.flexBetween}}>*/}
                {/*    <Text>Ballon Payment</Text>*/}
                {/*    <Text>{helper.balloon()}</Text>*/}
                {/*</View>*/}
            </View>
        </View>
    )
}