import React from 'react'
import {Document, Page, Text, View} from "@react-pdf/renderer";

import { baseStyles} from "../../styles";
import {FeeBreakdownPDF} from "../../components/FeeBreakdownPDF";
import {VehicleInfoPDF} from "../../components/VehicleInfoPDF";
import {LenderInfoPDF} from "../../components/LenderInfoPDF";
import {LocationInfoPDF} from "../../components/LocationInfoPDF";
import {PurchaseDealerPriceBreakdownPDF} from "../../Dealer/Purchase/PurchaseDealerPriceBreakdownPDF";
import {PurchaseDealerDownPaymentBreakdownPDF} from "../../Dealer/Purchase/PurchaseDealerDownPaymentBreakdownPDF";
import {PurchaseDealerFinanceDetailsPDF} from "../../Dealer/Purchase/PurchaseDealerFinanceDetailsPDF";
import {PurchaseDealerTaxInformationPDF} from "../../Dealer/Purchase/PurchaseDealerTaxInformationPDF";
import {PurchaseCustomerPaymentInformationPDF} from "./PurchaseCustomerPaymentInformationPDF";
import {PurchaseCustomerTradeInformationPDF} from "./PurchaseCustomerTradeInformationPDF";

export const PurchaseCustomerDisclosurePDF = ( { deal } ) => {
    if (!deal || ! deal.id) return null;

    return (
        <Document>
            <Page size="A4" style={baseStyles.page}>
                <View style={{
                    ...baseStyles.flexBetween,
                    fontSize: '20px',
                    fontWeight: '600',
                    alignItems: 'center',
                    alignContent: 'center',
                }}>
                    <Text
                        style={{fontSize: '10px'}}
                    >Customer Disclosure</Text>
                    <Text>TrueDesking</Text>
                    <Text
                        style={{fontSize: '10px'}}
                    >{new Date().toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})}</Text>
                </View>

                <View style={{...baseStyles.header}}>
                    <VehicleInfoPDF deal={deal}/>
                    <LenderInfoPDF deal={deal}/>
                    <LocationInfoPDF deal={deal}/>
                </View>

                <View style={{...baseStyles.flexBetween}}>
                    <View style={{...baseStyles.flexColumn, ...baseStyles.leftColumn}}>
                        <PurchaseCustomerPaymentInformationPDF deal={deal}/>
                        <PurchaseCustomerTradeInformationPDF deal={deal}/>
                        <FeeBreakdownPDF deal={deal}/>
                    </View>

                    <View style={{...baseStyles.flexColumn, ...baseStyles.rightColumn}}>
                        <PurchaseDealerPriceBreakdownPDF deal={deal}/>
                        <PurchaseDealerDownPaymentBreakdownPDF deal={deal}/>
                        <PurchaseDealerFinanceDetailsPDF deal={deal}/>
                        <PurchaseDealerTaxInformationPDF deal={deal}/>
                    </View>
                </View>

            </Page>
        </Document>
    )
}