import QuoteHelper from "../../../../utils/QuoteHelper";
import {Text, View} from "@react-pdf/renderer";
import React from "react";

import { baseStyles } from "../styles";

export const FeeBreakdownPDF = ( { deal } ) => {
    if (!deal) return null;

    const helper = new QuoteHelper(deal)

    const fees = helper.notRegistrationFees()

    return (
        <View style={{...baseStyles.table}}>
            <View style={baseStyles.tableHeader}>
                <Text>Fee Breakdown</Text>
            </View>

            <View style={{...baseStyles.row, ...baseStyles.spaceBetween, ...baseStyles.tablePadding}}>
                <Text
                    style={{...baseStyles.bold, width: '90%'}}
                >
                    Type
                </Text>

                <Text
                    style={{...baseStyles.bold, ...baseStyles.alignEnd, width: '10%', textAlign: 'end'}}
                >
                    Amt
                </Text>
            </View>
            <View style={baseStyles.tablePadding}>
                <View style={{...baseStyles.row, ...baseStyles.spaceBetween}}>
                    <Text
                        style={{width: '90%'}}
                    >
                        Registration Fees
                    </Text>
                    <Text
                        style={{width: '10%', textAlign: 'end'}}
                    >
                        { helper.totalRegistrationFees() }
                    </Text>
                </View>
                {
                    fees.map((t) => {
                        return (
                            <View
                                key={t.TitleId}
                                style={{...baseStyles.row, ...baseStyles.spaceBetween}}
                            >
                                <Text
                                    style={{width: '90%'}}
                                >
                                    {t.Name}
                                </Text>
                                <Text
                                    style={{width: '10%', textAlign: 'end'}}
                                >
                                    {t.Amount}
                                </Text>
                            </View>
                        )
                    })
                }
                <View
                    style={{...baseStyles.row, ...baseStyles.spaceBetween, ...baseStyles.boldTopBorder}}
                >
                    <Text
                        style={{width: '90%'}}
                    >
                        Total Fees
                    </Text>
                    <Text
                        style={{width: '10%', textAlign: 'end'}}
                    >
                        {helper.totalFeeAmount()}
                    </Text>
                </View>
            </View>
        </View>
    )
}