import {Text, View} from "@react-pdf/renderer";
import React from "react";
import QuoteHelper from "../../../../utils/QuoteHelper";
import {baseStyles} from "../styles";

export const LenderInfoPDF = ( { deal } ) => {
    const helper = new QuoteHelper(deal)
    return (
        <View>
            <Text>
                <Text
                    style={{...baseStyles.bold}}
                >
                    Lender:
                </Text>
                <Text> { helper.lender() } </Text>

                <Text
                    style={{...baseStyles.bold}}
                >
                    Lease Type:
                </Text>
                <Text> { helper.leaseType() } </Text>

                <Text
                    style={{...baseStyles.bold}}
                >
                    Term:
                </Text>
                <Text> { deal.term } </Text>
            </Text>
        </View>
    )
}