import {NavLink, useLocation} from "react-router-dom";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import CalculateIcon from '@mui/icons-material/Calculate';
import React from "react";
import {useAuthContext} from "../../contexts/AuthContext";
import {useUserContext} from "../../contexts/UserContext";
import {useMediaQuery} from "react-responsive";
import {barHeight} from "./AppMobileNav";
import CookieService from '../../utils/CookieService'
import {mediaQueryForMobile} from "./HomePage";
import {useCalcContext} from "../../contexts/CalcContext";

export const AppNavLinks = () => {
    const isTabletOrMobile = useMediaQuery({ query: mediaQueryForMobile })
    const { logout } = useAuthContext()
    const { profileState } = useUserContext()
    const { resetCalcState } = useCalcContext()
    let location = useLocation();
    const pathname = location.pathname

    const isPricingPath = pathname.split('/')[1] === 'pricing'
    const isCalculatePath = pathname.split('/')[1] === 'calculate'

    const lastPricingRoute = () => {
        return profileState?.pages_visited?.find((route) => route.includes('/pricing/')) || '/pricing/honda'
    }

    const shouldReloadDoc = CookieService.getItem("refreshClient") === true

    return (
        <div className={'AppNavLinks'}>
            {
                isTabletOrMobile ? <div style={{height: `${barHeight}px`}}></div> : null
            }
            <div className={'AppSidebarNav-top'}>
                <div className={'navlink-container'}>
                    <NavLink
                        onClick={() => {
                            if (isCalculatePath) {
                                resetCalcState()
                            }
                        }}
                        to="/calculate"
                        className={({ isActive, isPending, isTransitioning }) =>
                            [
                                "navlink",
                                isPending ? "pending" : "",
                                isActive ? "active" : "",
                                isTransitioning ? "transitioning" : "",
                            ].join(" ")
                        }
                    >
                        <CalculateIcon/> <span>Calculate</span>
                    </NavLink>
                    <NavLink
                        reloadDocument={shouldReloadDoc}
                        to="/deals"
                        className={({ isActive, isPending, isTransitioning }) =>
                            [
                                "navlink",
                                isPending ? "pending" : "",
                                isActive ? "active" : "",
                                isTransitioning ? "transitioning" : "",
                            ].join(" ")
                        }
                    >
                        <RequestQuoteIcon/> <span>Deals</span>
                    </NavLink>
                    {
                        !isTabletOrMobile &&
                        <NavLink
                            reloadDocument={shouldReloadDoc}
                            to={lastPricingRoute()}
                            className={({ isActive, isPending, isTransitioning }) =>
                                [
                                    "navlink",
                                    isPending ? "pending" : "",
                                    isPricingPath ? "active" : "",
                                    isTransitioning ? "transitioning" : "",
                                ].join(" ")
                            }
                        >
                            <LocalOfferIcon/> <span>Pricing List</span>
                        </NavLink>
                    }
                    <NavLink
                        reloadDocument={shouldReloadDoc}
                        to="/account/profile"
                        className={({ isActive, isPending, isTransitioning }) =>
                            [
                                "navlink",
                                isPending ? "pending" : "",
                                isActive ? "active" : "",
                                isTransitioning ? "transitioning" : "",
                            ].join(" ")
                        }
                    >
                        <PersonIcon/> <span>Profile</span>
                    </NavLink>
                </div>

            </div>
            <div className={'AppSidebarNav-bottom'}>
                <div className={'navlink'} onClick={() => logout()}>
                    <SettingsIcon/> <span>Log out</span>
                </div>
            </div>
        </div>
    )
}