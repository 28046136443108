import {StyleSheet, Font} from "@react-pdf/renderer";

import Roboto from '../../../assets/fonts/Roboto/Roboto-Regular.ttf';
import RobotoItalic from '../../../assets/fonts/Roboto/Roboto-Italic.ttf';
import RobotoBold from '../../../assets/fonts/Roboto/Roboto-Bold.ttf';
import RobotoBoldItalic from '../../../assets/fonts/Roboto/Roboto-BoldItalic.ttf';

Font.register({
    family: 'Roboto',
    fonts: [
        {
            src: Roboto,
        },
        {
            src: RobotoBold,
            fontWeight: 'bold',
        },
        {
            src: RobotoItalic,
            fontWeight: 'normal',
            fontStyle: 'italic',
        },
        {
            src: RobotoBoldItalic,
            fontWeight: 'bold',
            fontStyle: 'italic',
        },
    ],
    format: 'truetype',
});

export const baseStyles = StyleSheet.create({
    page: {
        padding: '20px 40px',
        fontFamily: 'Roboto',
        fontSize: '10px'
    },
    table: {
        width: '100%',
        marginBottom: '5px',
        border: '1px solid #D3D3D3',
    },
    marginRight: {
        marginRight: '20px'
    },
    topLine: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '10px'
    },
    header: {
        borderTop: '1px solid #D3D3D3',
        borderBottom: '1px solid #D3D3D3',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    tableHeader: {
        fontWeight: '700',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#D3D3D3',
        padding: '5px 0px'
    },
    bold: {
        fontWeight: '700',
    },
    boldTopBorder: {
        fontWeight: '700',
        borderTop: '1px solid #D3D3D3'
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        width: '100%',
    },
    flexColumn: {
        display: 'flex',
        // justifyContent: 'space-between',
        flexDirection: 'column',
        width: '50%',
        flexGrow: 1,
    },
    bottomBorder: {
        borderBottom: '2px solid black',
    },
    marginTop: {
        marginTop: '20px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
    },
    flexGrow: {
        flexGrow: 1,
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    alignEnd: {
        alignSelf: 'end',
        display: 'flex',
        alignItems: 'flex-end'
    },
    tablePadding: {
        padding: '0 5px'
    },
    leftColumn: {
        marginRight: '2px'
    },
    rightColumn: {
        marginLeft: '2px'
    },
    sumRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
        fontWeight: '700',
        borderTop: '1px solid #D3D3D3'
    }
});
