import {Text, View} from "@react-pdf/renderer";
import React from "react";
import {baseStyles} from "../styles";

export const VehicleInfoPDF = ( { deal } ) => {
    const car = deal.car_detail
    return (
        <View>
            <Text style={{...baseStyles.bold}}>
                {car.year} {car.make} {car.model} {car.trim} {car.style_description}
            </Text>
        </View>
    )
}